import React from "react"

// Libraries
import styled from "styled-components"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from "gatsby-background-image"

// Utils
import { colors } from "utils/variables/"
// import breakpoint from "utils/breakpoints/"

const StyledCardAgend = styled.div`
  width: 100%;
  position: relative;
  box-shadow: 0px 10px 50px rgba(13, 38, 59, 0.1);

  .agent__image {
    width: 100%;
    padding-bottom: 133.33%;
    background-size: cover;
  }

  .agent__name {
    position: absolute;
    left: 16px;
    right: 16px;
    margin: auto;
    bottom: -36px;
    padding: 16px;
    background-color: ${colors.white};
    box-shadow: 0px 10px 50px rgba(13, 38, 59, 0.1);
  }
`

const CardAgent = ({ className, nombreCompleto, fotoDePerfil, posicion }) => {
  const image = getImage(fotoDePerfil)
  const bgImage = convertToBgImage(image)

  return (
    <StyledCardAgend className={className}>
      <BackgroundImage {...bgImage} className="agent__image" />

      <div className="agent__name text-center">
        <p className="font-weight--700">{nombreCompleto}</p>
        <p className="paragraph--small color--grey">{posicion}</p>
      </div>
    </StyledCardAgend>
  )
}

export default CardAgent
