import React from "react"

// Libraries
import styled from "styled-components"

// Utils
import breakpoint from "utils/breakpoints/"

// Components
import Container from "components/container/"
import CardAgent from "components/card-agent/"

const StyledAgents = styled.section`
  padding: 64px 0;

  ${breakpoint.medium`
    padding: 96px 0;
  `}

  .agente {
    margin-bottom: 64px;
  }
`

const Agents = ({ data: agents }) => {
  return (
    <StyledAgents>
      <Container>
        <div className="agentes__title mb-5 text-center">
          <h3 className="mb-2">Conocé a nuestro equipo</h3>
          <p className="color--grey">
            Ad exercitation minim commodo amet ad magna sint qui aliqua deserunt
            cupidatat.
          </p>
        </div>
        <div className="row">
          {agents &&
            agents.map((agent, index) => (
              <div className="col-12 col-sm-6 col-md-3" key={index}>
                <CardAgent {...agent} className="agente" />
              </div>
            ))}
        </div>
      </Container>
    </StyledAgents>
  )
}

export default Agents
