import React from "react"

// Libraries
import { useStaticQuery, graphql } from "gatsby"

// Sections
import Hero from "sections/home/hero"
import FeaturedProperties from "sections/home/featured-properties"
import PorQueElegirnos from "sections/home/por-que-elegirnos"
import Testimonios from "sections/home/testimonios"
import Agentes from "sections/home/agentes"

const Home = () => {
  const { contentfulHome: home } = useStaticQuery(graphql`
    query {
      contentfulHome(titulo: { eq: "Home" }) {
        propiedadesDestacadas {
          id
          slug
          titulo
          enVenta
          precio
          precioDeDescuento
          expensas
          galeriaDeFotos {
            gatsbyImageData(width: 350, quality: 100)
          }
          descripcion {
            raw
          }
          metrosCuadrados
          cantidadDeHabitaciones
          cantidadDeBanos
        }

        testimonios {
          id
          nombre
          testimonio {
            testimonio
          }
          fotoDePerfil {
            gatsbyImageData(width: 60, quality: 100)
          }
        }

        agentes {
          id
          nombreCompleto
          posicion
          fotoDePerfil {
            gatsbyImageData(width: 269, quality: 100)
          }
          email
          whatsApp
        }
      }
    }
  `)
  return (
    <React.Fragment>
      <Hero />
      {home.propiedadesDestacadas && (
        <FeaturedProperties data={home.propiedadesDestacadas} />
      )}
      <PorQueElegirnos />
      {home.testimonios && <Testimonios data={home.testimonios} />}
      {home.agentes && <Agentes data={home.agentes} />}
    </React.Fragment>
  )
}

export default Home
