import React from "react"

// Libraries
import styled from "styled-components"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

// Utils
import { colors } from "utils/variables/"
import breakpoint from "utils/breakpoints/"

// Components
import Carousel from "components/carousel/"

// Icons
import IconCaret from 'assets/icons/icon-caret.inline.svg'
import IconSize from "assets/icons/property/icon-size.inline.svg"
import IconBedrooms from "assets/icons/property/icon-bedrooms.inline.svg"
import IconBathrooms from "assets/icons/property/icon-bathrooms.inline.svg"

const StyledPropertyCard = styled(Link)`
  display: block;
  border-radius: 8px;
  transition: all 0.3s ease;
  overflow: hidden;

  .card__carousel-wrapper {
    width: 100%;
    position: relative;
    background-color: ${colors.dark};

    .price {
      position: absolute;
      bottom: 16px;
      left: 20px;
    }

    .badge {
      position: absolute;
      top: 16px;
      left: 20px;
      font-size: 12px;
      line-height: 14px;
      border-radius: 3px;
    }

    .carousel {
      &:hover {

        .slick-arrow {
          opacity: 1;

          &.slick-prev {
            left: 20px;
          }

          &.slick-next {
            right: 20px;;
          }
        }
      }

      .slick-arrow {
        opacity: 0;
        transition: all 0.3s ease!important;
        
        &.slick-next {
          right: 0;
        }

        &.slick-prev {
          left: 0;

          svg {
            transform: rotate(180deg);
          }
        }

        svg {
          width: 10px;
          height: auto;
          filter: drop-shadow(0px 0px 2px rgb(0 0 0 / 0.5));
        }
      }
    }
  }

  .card__content {
    padding: 20px;
    border: 1px solid ${colors.grey__light};
    border-top: 0;
  }
`

const PrevArrow = ({ className, style, onClick}) => (
  <button type="button" onClick={onClick} className={className + " svg--fill-white"} style={style}>
    <IconCaret />
  </button>
)

const NextArrow = ({ className, style, onClick}) => (
  <button type="button" onClick={onClick} className={className + " svg--fill-white"} style={style}>
    <IconCaret />
  </button>
)

const PropertyCard = (props) => {
  const {
    className,
    slug,
    titulo,
    direccion,
    galeriaDeFotos,
    descripcion,
    enVenta,
    precio,
    precioDeDescuento,
    expensas,
    metrosCuadrados,
    cantidadDeHabitaciones,
    cantidadDeBanos,
  } = props

  const carouselOptions = {
    infinite: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
  }

  return (
    <StyledPropertyCard className={className} to={"/propiedades/" + slug}>
      <div className="card__carousel-wrapper">
        <Carousel className="carousel" {...carouselOptions}>
          {galeriaDeFotos.map((foto, index) => (
            <GatsbyImage
              key={index}
              className="image"
              image={getImage(foto)}
              alt={titulo}
            />
          ))}
        </Carousel>
        {enVenta ? (
          <span className="badge p-1 bg--blue color--white font-weight--700">
            EN VENTA
          </span>
        ) : (
          <span className="badge p-1 bg--blue color--white font-weight--700">
            EN ALQUILER
          </span>
        )}
      </div>

      <div className="card__content">
        <p className="mb-3 font-weight--700">{titulo}</p>

        <div className="price mb-3">
          {precioDeDescuento && (
            <p className="paragraph--small color--grey">
              <s>{precioDeDescuento}</s>
            </p>
          )}

          <p className="font-weight--700">
            {precio}
            {expensas && ` + ${expensas} expensas`}
          </p>
        </div>

        <div className="amenities d-flex align-items-center justify-content-between">
          {metrosCuadrados && (
            <div className="amenity text-center">
              <div className="icon d-flex justify-content-center mb-1">
                <IconSize />
              </div>
              <p className="paragraph--small color--grey">{metrosCuadrados}</p>
            </div>
          )}

          {cantidadDeHabitaciones > 0 && (
            <div className="amenity text-center">
              <div className="icon d-flex justify-content-center mb-1">
                <IconBedrooms />
              </div>
              <p className="paragraph--small color--grey">
                {cantidadDeHabitaciones} dorm.
              </p>
            </div>
          )}

          {cantidadDeBanos > 0 && (
            <div className="amenity text-center">
              <div className="icon d-flex justify-content-center mb-1">
                <IconBathrooms />
              </div>
              <p className="paragraph--small color--grey">
                {cantidadDeBanos} baños
              </p>
            </div>
          )}
        </div>
      </div>
    </StyledPropertyCard>
  )
}

export default PropertyCard
