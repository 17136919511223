import React from "react"

// Libraries
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

// Utils
import { colors } from "utils/variables/"
import breakpoint from "utils/breakpoints/"

const StyledCardTestimonio = styled.div`
  width: 100%;
  position: relative;

  .testimonio {
    padding: 64px 32px;
    box-shadow: 0px 10px 50px rgba(13, 38, 59, 0.1);

    ${breakpoint.large`
      // padding: 32px 32px 64px 32px;
    `}
  }

  .testimonio__autor {
    position: absolute;
    top: calc(100% - 30px);
    left: 0;
    right: 0;

    .author-image {
      border: 3px solid ${colors.white};
      border-radius: 50%;
      box-sizing: border-box !important;
      box-shadow: 0px 10px 50px rgba(13, 38, 59, 0.1);
    }
  }
`

const CardTestimonio = ({ className, nombre, testimonio, fotoDePerfil }) => (
  <StyledCardTestimonio className={className}>
    <div className="testimonio bg--white color--gray text-center">
      <p className="paragraph--small">"{testimonio.testimonio}"</p>
    </div>

    <div className="testimonio__autor text-center">
      <GatsbyImage
        image={getImage(fotoDePerfil)}
        className="author-image mb-3"
        alt=""
      />
      <p className="font-weight--700">{nombre}</p>
    </div>
  </StyledCardTestimonio>
)

export default CardTestimonio
