import React from "react"

// Libraries
import styled from "styled-components"
import Carousel from "react-slick"

// Utils
import { colors } from "utils/variables/"
import breakpoint from "utils/breakpoints/"

// Styles
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const StyledCarousel = styled(Carousel)`
  .slick-list {
    margin: 0 -16px;
  }

  .slick-slide {
    > div {
      padding: 0 16px;
    }
  }

  .slick-arrow {
    z-index: 10;

    &::before {
      display: none;
    }
  }

  .slick-dots {
    height: 8px;
    bottom: -32px;
    display: flex !important;
    align-items: center;
    justify-content: center;

    ${breakpoint.medium`
      bottom: -64px;
    `}

    li {
      width: 10px;
      height: 10px;
      margin: 0 8px 0 0;

      &:last-child {
        margin: 0;
      }

      &:hover:not(.slick-active) {
        button {
          background-color: ${colors.dark};
        }
      }

      &.slick-active {
        button {
          background-color: ${colors.white};
          border-color: ${colors.blue};
        }
      }

      button {
        width: 10px;
        height: 10px;
        padding: 0;
        background-color: ${colors.blue};
        border: 2px solid ${colors.white};
        border-radius: 50%;
        transition: all 0.3s ease;

        &::before {
          content: none;
          display: none;
        }
      }
    }
  }
`

export default (props) => (
  <StyledCarousel {...props}>{props.children}</StyledCarousel>
)
