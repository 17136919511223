import React from "react"

// Libraries
import styled from "styled-components"

// Utils
import breakpoint from "utils/breakpoints/"

// Components
import Container from "components/container/"
import Carousel from "components/carousel/"
import PropertyCard from "components/property-card/"

const StyledFeaturedProperties = styled.section`
  padding: 60px 0 36px 0;

  ${breakpoint.medium`
    padding: 120px 0 96px 0;
  `}

  .property {
    margin-bottom: 24px;
  }
`

const FeaturedProperties = ({ data }) => {
  const carouselOptions = {
    autoplay: true,
    autoplaySpeed: 4000,
    infinite: true,
    dots: true,
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          arrows: false,
        },
      },
    ],
  }

  return (
    <StyledFeaturedProperties>
      <Container>
        <div className="property__heading mb-4 mb-md-5 text-center">
          <h2>Descubrí nuestras propiedades destacadas</h2>
        </div>
        
        <div className="featured-properties row">
          {data && data.map((property, index) => (
            <div className="col-12 col-sm-6 col-md-4" key={index}>
              <PropertyCard {...property} className="property" />
            </div>
          ))}
        </div>
      </Container>
    </StyledFeaturedProperties>
  )
}

export default FeaturedProperties
