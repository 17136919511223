import React from "react"

// Libraries
import styled from "styled-components"

// Utils
import breakpoint from "utils/breakpoints/"

// Components
import Container from "components/container/"
import Carousel from "components/carousel/"
import CardTestimonio from "components/card-testimonio/"

// Icons
import Texture from "assets/icons/texture.svg"

const StyledTestimonios = styled.section`
  padding: 64px 0 156px 0;
  background-size: auto 100%;

  ${breakpoint.medium`
    padding: 96px 0 156px 0;
  `}

  .testimonios__carousel {
    .slick-list {
      overflow: visible !important;
    }

    .slick-dots {
      bottom: -124px;
    }
  }
`

const Testimonios = ({ data: testimonios }) => {
  const carouselOptions = {
    dots: false,
    slidesToShow: 4,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          dots: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          dots: true,
        },
      },
    ],
  }
  return (
    <StyledTestimonios
      className="bg--grey__ultraLight"
      style={{ backgroundImage: `url("${Texture}")` }}
    >
      <Container>
        <div className="testimonios__title mb-4 mb-md-5 text-center">
          <h3 className="mb-2 font-weight--500">Testimonios</h3>
          <p>Eu consectetur anim ea laborum nostrud ut eiusmod dolore duis.</p>
        </div>

        <Carousel {...carouselOptions} className="testimonios__carousel">
          {testimonios &&
            testimonios.map((testimonio, index) => (
              <CardTestimonio {...testimonio} key={index} />
            ))}
        </Carousel>
      </Container>
    </StyledTestimonios>
  )
}

export default Testimonios
