import React, { useState } from "react"

// Libraries
import styled from "styled-components"

// Utils
import { colors } from "utils/variables/"
import breakpoint from "utils/breakpoints/"

// Components
import Container from "components/container/"

// Icons
import IconHouses from "assets/icons/icon-houses.inline.svg"
import IconSearch from "assets/icons/icon-search.inline.svg"

const StyledHero = styled.section`
  width: 100%;
  height: 400px;

  ${breakpoint.medium`
    height: calc(100vh - 89px);
  `}

  h2 {
    margin-bottom: 40px;
  }

  .categories {
    .category {
      margin-right: 24px;
      font-weight: 700;

      &:last-child {
        margin-right: 0;
      }

      &::before {
        background-color: ${colors.white}!important;
      }
    }
  }

  .search-wrapper {
    padding: 10px;
    margin-top: 32px;
    background-color: ${colors.white};
    border-radius: 64px;

    ${breakpoint.medium`
      padding-left: 24px;
    `}

    svg {
      flex-shrink: 0;
    }

    input {
      width: 100%;
      height: 48px;
      margin: 0 8px;
      border: 0;
      color: ${colors.dark};
    }

    .submit {
      flex-shrink: 0;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      cursor: pointer;
      transition: all 0.3s ease;

      ${breakpoint.medium`
        width: 50px;
        height: 50px;
      `}
    }
  }
`

const Hero = () => {
  const [category, setCategory] = useState("Comprar")
  const [search, setSearch] = useState("")

  return (
    <StyledHero className="bg--dark d-flex align-items-center text-center color--white">
      <Container>
        <div className="row justify-content-center">
          <div className="col-sm-10 col-md-9">
            <h2>Find your perfect home</h2>
            <div className="row justify-content-center">
              <div className="col-md-8">
                <form>
                  <div className="categories text-center">
                    <button
                      type="button"
                      className={
                        category === "Comprar"
                          ? "category active fun-border"
                          : "category fun-border"
                      }
                      onClick={() => setCategory("Comprar")}
                    >
                      Comprar
                    </button>
                    <button
                      type="button"
                      className={
                        category === "Alquilar"
                          ? "category active fun-border"
                          : "category fun-border"
                      }
                      onClick={() => setCategory("Alquilar")}
                    >
                      Alquilar
                    </button>
                  </div>
                  <div className="search-wrapper d-flex align-items-center">
                    <IconHouses className="houses" />
                    <input
                      type="text"
                      name="search"
                      placeholder="Ingresá un barrio o localidad"
                    />

                    <button
                      type="submit"
                      className="submit bg--blue bg-hover--dark d-flex align-items-center justify-content-center"
                    >
                      <IconSearch />
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </StyledHero>
  )
}

export default Hero
