import React from "react"

// Libraries
import styled from "styled-components"

// Utils
import { colors } from "utils/variables/"
import breakpoint from "utils/breakpoints/"

// Components
import Container from "components/container/"

// Icons
import IconTrusted from "assets/icons/home/icon-trusted.inline.svg"
import IconVariety from "assets/icons/home/icon-variety.inline.svg"
import IconFinancing from "assets/icons/home/icon-financing.inline.svg"
import IconMap from "assets/icons/home/icon-map.inline.svg"

const StyledPorQueElegirnos = styled.section`
  padding: 60px 0;

  ${breakpoint.medium`
    padding: 120px 0;
  `}

  .por-que-elegirnos__item {
    padding: 40px 24px;
    border-radius: 3px;
    transition: all 0.3s ease;

    &:hover {
      transform: scale(1.05);

      .icon {
        background-color: ${colors.blue};

        svg {
          * {
            fill: ${colors.white};
          }
        }
      }
    }

    .icon {
      width: 112px;
      height: 112px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto 24px auto;
      border-radius: 50%;
      transition: all 0.3s ease;

      svg {
        * {
          transition: all 0.3s ease;
        }
      }
    }
  }
`

const PorQueElegirnos = () => {
  return (
    <StyledPorQueElegirnos className="bg--dark color--white text-center">
      <Container>
        <div className="mb-4 mb-md-5 por-que-elegirnos__title">
          <h3 className="mb-2 font-weight--500">¿Por qué elegirnos?</h3>
          <p>Eu consectetur anim ea laborum nostrud ut eiusmod dolore duis.</p>
        </div>

        <div className="row">
          <div className="col-12 col-sm-6 col-lg-3 mb-4 mb-lg-0">
            <div className="por-que-elegirnos__item bg--white color--dark">
              <div className="icon">
                <IconTrusted />
              </div>

              <p className="title mb-3 font-weight--600">
                Wide Renge Of Properties
              </p>
              <p className="paragraph--small">
                With over 1 million+ homes for sale available on the website,
                Trulia can match you with a house you will want to call home.
              </p>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-lg-3 mb-4 mb-lg-0">
            <div className="por-que-elegirnos__item bg--white color--dark">
              <div className="icon">
                <IconVariety />
              </div>

              <p className="title mb-3 font-weight--600">
                Wide Renge Of Properties
              </p>
              <p className="paragraph--small">
                With over 1 million+ homes for sale available on the website,
                Trulia can match you with a house you will want to call home.
              </p>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-lg-3 mb-4 mb-lg-0">
            <div className="por-que-elegirnos__item bg--white color--dark">
              <div className="icon">
                <IconFinancing />
              </div>

              <p className="title mb-3 font-weight--600">
                Wide Renge Of Properties
              </p>
              <p className="paragraph--small">
                With over 1 million+ homes for sale available on the website,
                Trulia can match you with a house you will want to call home.
              </p>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-lg-3">
            <div className="por-que-elegirnos__item bg--white color--dark">
              <div className="icon">
                <IconMap />
              </div>

              <p className="title mb-3 font-weight--600">
                Wide Renge Of Properties
              </p>
              <p className="paragraph--small">
                With over 1 million+ homes for sale available on the website,
                Trulia can match you with a house you will want to call home.
              </p>
            </div>
          </div>
        </div>
      </Container>
    </StyledPorQueElegirnos>
  )
}

export default PorQueElegirnos
